<template>
  <div>
    <el-card>
      <el-form ref="form" :model="openForm" :inline="true" size="small" @submit.native.prevent>
        <el-row>
          <el-col :md="8">
            <el-form-item label="样品编号:">
              <el-input v-model="openForm.smpl_no" placeholder="请输入样品编号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="卡使用状态:">
              <el-select v-model="openForm.rfid_status" placeholder="请选择" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="所属部门:">
              <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searFrom" class="vg_ml_16" icon="el-icon-search">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="4" class="vg-ml-15">
            <el-form-item>
              <el-button type="primary" size="small" @click="addCard">开卡</el-button>
              <el-button type="danger" size="small" @click="delCard()" :disabled="isDisabled">销卡</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        class="vg_cursor"
        @sort-change="sortChange"
        border
        :data="cardList"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @cell-dblclick="dblclick"
      >
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="stff_name" label="开卡人"> </el-table-column>
        <el-table-column prop="smpl_no" label="样品编号"> </el-table-column>
        <el-table-column prop="requ_no" label="委托单号"> </el-table-column>
        <el-table-column prop="belo_dept_name" label="所属部门"> </el-table-column>
        <el-table-column prop="rfid_no" label="卡号"> </el-table-column>
        <el-table-column prop="rfid_status" label="卡使用状态" align="center" sortable>
          <template slot-scope="scope">
            <el-tag size="small" :type="getRfidType(scope.row.rfid_status)">
              {{ getRfidStatus(scope.row.rfid_status) }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <pubPagination :totalPage="total" @changePageSearch="changeSampPageSearch" ref="pubPagination"></pubPagination>
    </el-card>
  </div>
</template>
<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid'; //rfid
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
export default {
  name: 'openCard',
  components: {
    pubPagination,
    selectSectionType
  },
  data() {
    return {
      openForm: {
        page_no: 1,
        smpl_no: null,
        open_sort: null,
        open_field: null,
        rfid_status: null,
        belo_dept_id: null
      },
      total: 0,
      cardList: [],
      multipleSelection: [],
      defaultValType: '',
      selectRefuDisabled: false,
      isDyj: true,
      beloDeptId: null,
      options: [
        {
          value: 0,
          label: '未使用'
        },
        {
          value: 1,
          label: '已完成'
        },
        {
          value: 2,
          label: '正在使用'
        }
      ]
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/open_list') {
        this.getCardList();
      }
    }
  },
  created() {
    this.getDepartment();
    this.getDepartment();
    this.getCardList();
  },
  methods: {
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if(userInfo.user_name === 'admin'){
        this.openForm.belo_dept_id = null;
        this.isDyj = false;
        return;
      }
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.openForm.belo_dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.openForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    getCardList() {
      get(rfidAPI.get_rfids, this.openForm)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error('获取数据失败');
          }
          this.cardList = res.data.data.list;
          this.total = res.data.data.total;
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    changeSampPageSearch(val) {
      this.openForm.page_no = val;
      this.getCardList();
    },
    searFrom() {
      this.openForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCardList();
    },
    // 刷新
    buttonRefresh() {
      this.openForm = {
        page_no: 1,
        smpl_no: null,
        open_sort: null,
        open_field: null,
        rfid_status: null
      };
      if (this.isDyj) {
        this.openForm.belo_dept_id = this.beloDeptId;
      } else {
        this.openForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.currentPage = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCardList();
    },
    addCard() {
      this.jump('open_add');
    },
    delCard() {
      if (this.multipleSelection.length == 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('此操作将永久删除该卡号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let rfid_id_list = [];
          for (let i in this.multipleSelection) {
            rfid_id_list.push(this.multipleSelection[i].rfid_id);
          }
          post(rfidAPI.destroy_rfid_by_ids, { rfid_id_list })
            .then(res => {
              if (res.data.code === 1) {
                return this.$message.warning('状态是正在使用或已完成的卡是不可以销卡');
              }
              if (res.data.code !== 0) {
                return this.$message.error(res.data.msg);
              }
              this.$message.success(res.data.msg);
              this.getCardList();
            })
            .catch(res => {});
        })
        .catch(() => {
          this.$message.info('已取消删除');
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dblclick(val) {
      this.jump('open_edit', { rfid_id: val.rfid_id });
    },
    //查询范围
    getStatusVal2(val) {
      this.openForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      if (val.order == 'ascending') {
        this.openForm.open_sort = 'asc';
        this.openForm.open_field = val.prop;
      } else if (val.order == 'descending') {
        this.openForm.open_sort = 'desc';
        this.openForm.open_field = val.prop;
      } else {
        this.openForm.open_sort = '';
        this.openForm.open_field = '';
      }
      this.openForm.page_no = 1;
      this.getCardList();

      // 升序 asc
      // 降序 desc
    },
    //获取卡的状态
    getRfidStatus(status) {
      if (status === 0) {
        return '正在使用';
      } else if (status === 1) {
        return '已完成';
      } else if (status === 2) {
        return '未使用';
      }
    },
    //通过状态改变 tag的颜色
    getRfidType(status) {
      if (status === 0) {
        return '';
      } else if (status === 1) {
        return 'success';
      } else if (status === 2) {
        return 'warning';
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.multipleSelection.length == 0) {
        return true;
      }
      let f = this.multipleSelection.some(item => {
        return item.rfid_status === 1;
      });
      return f;
    }
  }
};
</script>

<style scoped>
.el-table {
  margin-top: 15px;
}
.vg-ml-15 {
  margin-top: 15px;
}
::v-deep .el-form-item__content {
  margin-left: 0;
}
.vg_ml_32 {
  margin-left: 32px;
}
</style>
